import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import {
  GetItemResponse,
  Item,
  StockTake,
  ValidatedItem,
  StockTakingServiceErrorTypes,
  ItemValidationErrorType,
} from "@ahlsell-group/store20-stock-taking-service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ErrorActionPayload } from "../../util/toErrorActionPayload";
import navigatedTo from "../routing/navigatedTo";
import routes from "../routing/routes";

export const manualStockTakingStatuses = [
  "idle",
  "loadingItem",
  "itemOpen",
  "itemSaving",
  "submittingStockTakes",
] as const;
export type ManualStockTakingStatus =
  (typeof manualStockTakingStatuses)[number];

export type GetOrCreateManualStockTakeFailedReason =
  | StockTakingServiceErrorTypes["getOrCreateManualStockTake"]
  | GenericOperationError;

export type ReviewStockTakeFailedReason =
  | StockTakingServiceErrorTypes["reviewStockTake"]
  | GenericOperationError;

export interface ReviewStockTakeError {
  reason: ReviewStockTakeFailedReason;
  data?: Record<string, unknown>;
}

export type SubmitStockTakesFailedReason =
  | StockTakingServiceErrorTypes["submitStockTake"]
  | GenericOperationError;

export interface SubmitStockTakesError {
  reason: SubmitStockTakesFailedReason;
  data?: Record<string, unknown>;
}

export type DeleteItemError =
  | StockTakingServiceErrorTypes["deleteItem"]
  | StockTakingServiceErrorTypes["deleteAllItems"]
  | GenericOperationError;

export type UpdateItemError =
  | StockTakingServiceErrorTypes["updateItemStockTakingQuantity"]
  | GenericOperationError;

export type OpenItem = GetItemResponse & {
  error?: GenericOperationError | "barcode-not-found";
};

export interface StockTakeDeleteItemPayload {
  itemId: string;
  navigateBackSteps: number;
}

export interface StockTakeReviewToggledPayload {
  isReviewEnabled: boolean;
  stockTake: StockTake;
}

export type ValidationErrorRecord = Record<
  string,
  ItemValidationErrorType | "ExpectedQuantityDiffers"
>;

export interface ManualStockTakingState {
  status: ManualStockTakingStatus;
  isReviewEnabled?: boolean;
  currentStockTakeId?: string;
  openItem?: OpenItem;
  items: Item[];
  initStockTakeState?:
    | "loading"
    | { reason: GetOrCreateManualStockTakeFailedReason };
  updateItemError?: UpdateItemError;
  submitStockTakesError?: SubmitStockTakesError;
  deleteItemState: "idle" | "in-progress";
  deleteItemError?: DeleteItemError;
  reviewState?:
    | { type: "loading" }
    | {
        type: "ok";
        data: ValidatedItem[];
        validationErrors?: undefined;
        error?: undefined;
      }
    | {
        type: "validationError";
        data?: undefined;
        validationErrors: ValidationErrorRecord;
        error?: undefined;
      }
    | {
        type: "error";
        data?: undefined;
        validationErrors?: undefined;
        error: ReviewStockTakeError;
      };
}

export const initialState: ManualStockTakingState = {
  status: "idle",
  items: [],
  deleteItemState: "idle",
};

const name = "manualStockTaking";

export interface UpdateItemPayload {
  item: Item;
  continueScanning: boolean;
}

const subSlices = {
  idle: createSlice({
    name,
    initialState,
    reducers: {
      submitStockTakesRequested(
        state,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _action: PayloadAction<{ enableAutomaticCompletion: boolean }>
      ) {
        state.status = "submittingStockTakes";
        state.submitStockTakesError = undefined;
      },
    },
    extraReducers(builder) {
      builder
        .addMatcher(navigatedTo(routes.stockTaking.manual.item), (state) => {
          state.status = "loadingItem";
        })
        .addMatcher(
          navigatedTo(routes.stockTaking.manual.item.barcode),
          (state) => {
            state.status = "loadingItem";
          }
        );
    },
  }),
  loadingItem: createSlice({
    name,
    initialState,
    reducers: {
      itemLoaded(state, { payload }: PayloadAction<GetItemResponse>) {
        state.status = "itemOpen";
        state.openItem = payload;
        state.updateItemError = undefined;
      },
      itemLoadInvalidBarcode(state, { payload }: PayloadAction<string>) {
        state.status = "itemOpen";
        state.openItem = {
          canStockTake: false,
          itemId: payload,
          error: "barcode-not-found",
        };
      },
      itemLoadFailed(
        state,
        {
          payload,
        }: PayloadAction<{ itemId: string; reason: GenericOperationError }>
      ) {
        state.status = "itemOpen";
        state.openItem = {
          canStockTake: false,
          itemId: payload.itemId,
          error: payload.reason,
        };
      },
    },
  }),
  submittingStockTakes: createSlice({
    name,
    initialState,
    reducers: {
      submitStockTakesFailed(
        state,
        { payload: error }: PayloadAction<SubmitStockTakesError>
      ) {
        state.status = "idle";
        state.submitStockTakesError = error;
      },
      submitStockTakesSucceeded(state) {
        state.status = "idle";
        state.items = [];
        state.currentStockTakeId = undefined;
        state.reviewState = undefined;
      },
    },
  }),
  itemOpen: createSlice({
    name,
    initialState,
    reducers: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      updateItem(state, _action: PayloadAction<UpdateItemPayload>) {
        state.status = "itemSaving";
        state.updateItemError = undefined;
      },
      dismissUpdateItemError(state) {
        state.updateItemError = undefined;
      },
    },
  }),
  itemSaving: createSlice({
    name,
    initialState,
    reducers: {
      updateItemSucceeded(state, { payload }: PayloadAction<Item>) {
        state.status = "idle";
        state.updateItemError = undefined;
        const index = state.items.findIndex((x) => x.itemId === payload.itemId);
        if (index === -1) {
          state.items.push({ ...payload });
        } else {
          state.items[index] = { ...payload };
        }

        // Remove error from review-state if it exists. Will be re-evaluated once user tries to review again.
        if (state.reviewState?.type === "validationError") {
          state.reviewState.validationErrors[payload.itemId] ===
            "ExpectedQuantityDiffers" &&
            delete state.reviewState.validationErrors[payload.itemId];
          if (Object.keys(state.reviewState.validationErrors).length === 0) {
            state.reviewState = undefined;
          }
        }
      },
      updateItemFailed(
        state,
        { payload }: PayloadAction<{ reason: UpdateItemError }>
      ) {
        state.status = "itemOpen";
        state.updateItemError = payload.reason;
      },
    },
  }),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    // Used for saga.
    initStockTake() {},
    initStockTakeStarted(state) {
      state.initStockTakeState = "loading";
    },
    initStockTakeSucceeded(state, { payload }: PayloadAction<StockTake>) {
      state.currentStockTakeId = payload.stockTakeId;
      state.items = payload.items;
      state.initStockTakeState = undefined;
    },
    initStockTakeFailed(
      state,
      {
        payload,
      }: PayloadAction<
        ErrorActionPayload<GetOrCreateManualStockTakeFailedReason>
      >
    ) {
      state.initStockTakeState = payload;
    },

    stockTakeReviewToggled(
      state,
      { payload }: PayloadAction<StockTakeReviewToggledPayload>
    ) {
      state.isReviewEnabled = payload.isReviewEnabled;
      state.currentStockTakeId = payload.stockTake.stockTakeId;
      state.items = payload.stockTake.items;
    },

    // Used for saga.
    reviewStockTake(state) {
      state.reviewState = { type: "loading" };
    },
    reviewStockTakeLoaded(state, { payload }: PayloadAction<ValidatedItem[]>) {
      state.reviewState = {
        type: "ok",
        data: payload,
      };
    },
    reviewStockTakeValidationError(
      state,
      { payload }: PayloadAction<ValidationErrorRecord>
    ) {
      state.reviewState = {
        type: "validationError",
        validationErrors: payload,
      };
    },
    reviewStockTakeFailed(
      state,
      { payload }: PayloadAction<ReviewStockTakeError>
    ) {
      state.reviewState = { type: "error", error: payload };
    },
    dismissStockTakeReview(state) {
      state.reviewState = undefined;
    },

    // Used for saga.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteItem(state, _action: PayloadAction<StockTakeDeleteItemPayload>) {
      state.deleteItemState = "in-progress";
      state.deleteItemError = undefined;
    },
    deleteItemSucceeded(state, { payload: itemId }: PayloadAction<string>) {
      state.deleteItemState = "idle";
      state.deleteItemError = undefined;
      const index = state.items.findIndex((x) => x.itemId === itemId);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
      // Deleting an item after review failed, also delete from validationErrors.
      if (state.reviewState?.type === "validationError") {
        delete state.reviewState.validationErrors[itemId];
        if (Object.keys(state.reviewState.validationErrors).length === 0) {
          state.reviewState = undefined;
        }
      }
    },
    deleteItemFailed(
      state,
      { payload }: PayloadAction<{ reason: DeleteItemError }>
    ) {
      state.deleteItemState = "idle";
      state.deleteItemError = payload.reason;
    },

    deleteAllItems(state) {
      state.deleteItemState = "in-progress";
      state.deleteItemError = undefined;
    },
    deleteAllItemsSucceeded(state) {
      state.deleteItemState = "idle";
      state.deleteItemError = undefined;
      state.items = [];
      state.reviewState = undefined;
    },
    deleteAllItemsFailed(
      state,
      { payload }: PayloadAction<{ reason: DeleteItemError }>
    ) {
      state.deleteItemState = "idle";
      state.deleteItemError = payload.reason;
    },
    clearDeleteItemError(state) {
      state.deleteItemError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(navigatedTo(routes.stockTaking.manual), (state) => {
        state.status = "idle";
        state.openItem = undefined;
      })
      .addDefaultCase((state, action) =>
        subSlices[state.status].reducer(state, action)
      );
  },
});

export const {
  reducer: manualStockTakingReducer,
  actions: {
    initStockTake,
    initStockTakeStarted,
    initStockTakeSucceeded,
    initStockTakeFailed,
    stockTakeReviewToggled,
    reviewStockTake,
    reviewStockTakeLoaded,
    reviewStockTakeValidationError,
    reviewStockTakeFailed,
    dismissStockTakeReview,
    deleteItem,
    deleteItemSucceeded,
    deleteItemFailed,
    deleteAllItems,
    deleteAllItemsSucceeded,
    deleteAllItemsFailed,
    clearDeleteItemError,
  },
} = slice;

export const {
  idle: {
    actions: { submitStockTakesRequested },
  },
  loadingItem: {
    actions: { itemLoaded, itemLoadInvalidBarcode, itemLoadFailed },
  },
  itemOpen: {
    actions: { updateItem, dismissUpdateItemError },
  },
  itemSaving: {
    actions: { updateItemSucceeded, updateItemFailed },
  },
  submittingStockTakes: {
    actions: { submitStockTakesSucceeded, submitStockTakesFailed },
  },
} = subSlices;
