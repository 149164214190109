import { useTranslation } from "@ahlsell-group/app-localization";
import { ProgressDots } from "@ahlsell-group/app-ui/ProgressDots";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";

interface ManualStockTakingLoadingModalProps {
  headerText: string;
  detailText?: string;
  onClose?: () => void;
}

/**
 * A `<Modal>` that sets the cancel text automatically.
 */
const ManualStockTakingLoadingModal: React.FC<ManualStockTakingLoadingModalProps> =
  function ({ headerText, detailText, onClose }) {
    const { t } = useTranslation("common");

    return (
      <Modal open variant="card">
        <ModalHeader>{headerText}</ModalHeader>
        <ModalContent>
          {detailText}
          <div className="flex justify-center pt-6">
            <ProgressDots />
          </div>
        </ModalContent>
        {onClose && (
          <ModalActions>
            <ModalAction mode="button" variant="tertiary" onClick={onClose}>
              {t("cancel")}
            </ModalAction>
          </ModalActions>
        )}
      </Modal>
    );
  };

export default ManualStockTakingLoadingModal;
