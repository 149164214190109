import {
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { ProgressDotsLinear } from "@ahlsell-group/app-ui/ProgressDotsLinear";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectStockTakenSubmissionState } from "../stockTakenSubmissionSelectors";
import { submissionsRequested } from "../stockTakenSubmissionSlice";

import StockTakeSubmissionListItem from "./StockTakeSubmissionListItem";

interface StockTakenSubmissionListProps {
  highlightVivaldiId?: string;
}
const StockTakenSubmissionList: React.FC<StockTakenSubmissionListProps> =
  function ({ highlightVivaldiId }) {
    const { t } = useTranslation("common");
    const state = useSelector(selectStockTakenSubmissionState);
    const dispatch = useDispatch();

    const errorMessage = useErrorMessage(
      "stockTakenSubmission",
      state.type === "error" ? state.error : "unknown"
    );

    if (state.type === "idle") {
      throw new Error(
        "[internal error] StockTakenSubmission stuck in idle state"
      );
    }

    const stockTakes = state.type === "loaded" ? state.submissions : undefined;

    return (
      <div className="py-4">
        <Typography className="px-2 pb-4" color="gray" variant="body-sm">
          {t("stockTaking.stockTakenSubmissions")}
          {state.type === "loading" && (
            <ProgressDotsLinear
              size="small"
              className="ml-4"
              data-cy="StockTakeSubmissions-loading"
            />
          )}
        </Typography>

        {state.type === "error" && (
          <Alert severity="error" data-cy="StockTakeSubmissions-error">
            <div className="flex flex-col gap-1 text-body-sm">
              <div>
                <Trans t={t} i18nKey={errorMessage?.headerKey} />
              </div>
              <div>
                <Trans t={t} i18nKey={errorMessage?.detailKey} />
              </div>
              <div>
                <LinkButton
                  className="-ml-2"
                  onClick={() => dispatch(submissionsRequested())}
                >
                  {t("tryAgain")}
                </LinkButton>
              </div>
            </div>
          </Alert>
        )}
        {stockTakes?.length === 0 && (
          <Typography
            className="px-2"
            color="gray"
            variant="body-sm"
            data-cy="StockTakeSubmissions-empty"
          >
            {t("stockTaking.stockTakenSubmissionsEmpty")}
          </Typography>
        )}
        {stockTakes !== undefined && stockTakes.length > 0 && (
          <div data-cy="StockTakenSubmissions-list">
            {stockTakes.map((x) => (
              <StockTakeSubmissionListItem
                key={x.vivaldiId}
                submission={x}
                highlight={highlightVivaldiId === x.vivaldiId?.toString()}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

export default StockTakenSubmissionList;
