import { select, call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  deleteAllItemsFailed,
  deleteAllItemsSucceeded,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export default function* removeAllItemsSaga(
  serviceContainer: ServiceContainer
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  try {
    const stockId: number = yield select(selectWarehouseId);

    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    yield call([stockTakingService, "deleteAllItems"], stockId, stockTakeId);

    yield put(deleteAllItemsSucceeded());
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:removeAllItemsSaga:${errorCode}`,
      e,
      put(deleteAllItemsFailed(toErrorActionPayload(e)))
    );
  }
}
