import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { appStarted } from "../../app/appSlice";
import { ServiceContainer } from "../../app/serviceContainer";
import clearUserAndReloadSaga from "../warehouse/sagas/clearUserAndReloadSaga";
import { reloadForWarehouseUpdateRequested } from "../warehouse/warehouseSlice";

import {
  deleteAllItems,
  deleteItem,
  updateItem,
  submitStockTakesRequested,
  submitStockTakesSucceeded,
  initStockTake,
  deleteItemFailed,
  deleteAllItemsFailed,
  updateItemFailed,
  itemLoadFailed,
  reviewStockTake,
} from "./manualStockTakingSlice";
import initializeManualStockTaking from "./sagas/initializeManualStockTaking";
import loadStockTakenSubmissions from "./sagas/loadStockTakenSubmissions";
import { loadStockTakeReview } from "./sagas/loadStockTakeReview";
import removeAllItemsSaga from "./sagas/removeAllItemsSaga";
import removeItemSaga from "./sagas/removeItemSaga";
import saveItemSaga from "./sagas/saveItemSaga";
import submitStockTakes from "./sagas/submitStockTakes";
import { submissionsRequested } from "./stockTakenSubmissionSlice";

export default function* stockTakingSagas(serviceContainer: ServiceContainer) {
  yield all([
    takeEvery(
      submitStockTakesRequested.type,
      submitStockTakes,
      serviceContainer
    ),

    takeEvery(updateItem.type, saveItemSaga, serviceContainer),
    takeEvery(deleteItem.type, removeItemSaga, serviceContainer),
    takeEvery(deleteAllItems.type, removeAllItemsSaga, serviceContainer),
    takeEvery(reviewStockTake.type, loadStockTakeReview, serviceContainer),
    takeEvery(
      submissionsRequested.type,
      loadStockTakenSubmissions,
      serviceContainer
    ),

    takeEvery(
      [
        // When the app started and when a new initialization is explicitly requested.
        appStarted.type,
        initStockTake.type,

        // When the stock-take is no longer the current one.
        submitStockTakesSucceeded.type,

        // Errors may occur because of stale data. Refresh the data upon various errors.
        itemLoadFailed.type,
        updateItemFailed.type,
        deleteItemFailed.type,
        deleteAllItemsFailed.type,
      ],
      initializeManualStockTaking,
      serviceContainer
    ),

    takeLatest(
      reloadForWarehouseUpdateRequested.type,
      clearUserAndReloadSaga,
      serviceContainer
    ),
  ]);
}
