import { Warehouse } from "@ahlsell-group/store20-store-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { selectWarehouseId } from "../warehouseSelectors";
import { userWarehouseInfoUpdated } from "../warehouseSlice";

function* loadUserWarehouseInfoSaga({
  storeService,
  appTelemetryService,
  handleSagaError,
}: ServiceContainer): Saga {
  try {
    const warehouseId: number = yield select(selectWarehouseId);
    const warehouse: Warehouse = yield call(
      [storeService, "getWarehouseById"],
      warehouseId
    );

    yield put(userWarehouseInfoUpdated(warehouse));
    yield call([appTelemetryService, "updateSessionInfo"], {
      warehouseName: warehouse.storeName,
    });
  } catch (error) {
    yield* handleSagaError(
      (errorCode) => `saga:warehouse:loadUserWarehouseInfoSaga:${errorCode}`,
      error
    );
  }
}

export default loadUserWarehouseInfoSaga;
