import { StockTake } from "@ahlsell-group/store20-stock-taking-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  initStockTakeSucceeded,
  initStockTakeFailed,
  initStockTakeStarted,
} from "../manualStockTakingSlice";

/**
 * Attempt to initialize a stock-take, returning its ID.
 */
export default function* initializeManualStockTaking({
  stockTakingService,
  handleSagaError,
}: ServiceContainer): Saga<string | undefined> {
  const stockId: number = yield select(selectWarehouseId);
  try {
    yield put(initStockTakeStarted());
    const stockTake: StockTake = yield call(
      [stockTakingService, "getOrCreateManualStockTake"],
      stockId
    );
    yield put(initStockTakeSucceeded(stockTake));
    return stockTake.stockTakeId;
  } catch (e) {
    yield* handleSagaError(
      (errorCode) =>
        `saga:stock-taking:initializeManualStockTaking:${errorCode}`,
      e,
      put(initStockTakeFailed(toErrorActionPayload(e)))
    );
    return undefined;
  }
}
