import { call, put, select } from "redux-saga/effects";

import { selectConfig } from "../../../app/appSelectors";
import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import updateConfigSaga from "../../config/sagas/updateConfigSaga";
import loadInventoryLocationChangeErrorsSaga from "../../inventory-location/sagas/loadInventoryLocationChangeErrorsSaga";
import {
  selectBackgroundUpdateTimestamps,
  selectIsBackgroundUpdateRunning,
} from "../backgroundUpdateSelectors";
import {
  BackgroundUpdateStateLastPerformed,
  BackgroundUpdateType,
  backgroundUpdatePerformed,
  backgroundUpdateStarted,
} from "../backgroundUpdateSlice";

const backgroundUpdateTypeSagas: Record<
  BackgroundUpdateType,
  (sc: ServiceContainer) => Saga<boolean>
> = {
  config: updateConfigSaga,
  inventoryLocationErrors: loadInventoryLocationChangeErrorsSaga,
};

/**
 * Run background updates if needed.
 */
export function* backgroundUpdateSaga(
  serviceContainer: ServiceContainer
): Saga {
  const { updateIntervalMinutes }: BootstrapConfig = yield select(selectConfig);

  const isBackgroundUpdateRunning: boolean = yield select(
    selectIsBackgroundUpdateRunning
  );

  const timestamps: BackgroundUpdateStateLastPerformed = yield select(
    selectBackgroundUpdateTimestamps
  );

  if (isBackgroundUpdateRunning) {
    return;
  }

  const minute = 1000 * 60;
  const now = Date.now();

  const updatesToTry: BackgroundUpdateType[] = [];

  if (
    timestamps.config === undefined ||
    timestamps.config < now - minute * updateIntervalMinutes
  ) {
    updatesToTry.push("config");
  }

  if (
    timestamps.inventoryLocationErrors === undefined ||
    timestamps.inventoryLocationErrors < now - minute * 10
  ) {
    updatesToTry.push("inventoryLocationErrors");
  }

  if (!updatesToTry.length) {
    return;
  }

  yield put(backgroundUpdateStarted());

  const updatesPerformed: BackgroundUpdateType[] = [];

  for (let i = 0; i < updatesToTry.length; i += 1) {
    const type = updatesToTry[i];
    const saga = backgroundUpdateTypeSagas[type];
    const didUpdate: boolean = yield call(saga, serviceContainer);
    if (didUpdate) {
      updatesPerformed.push(type);
    }
  }

  yield put(
    backgroundUpdatePerformed({
      types: updatesPerformed,
      timestamp: now,
    })
  );
}
