import { ValidatedItem } from "@ahlsell-group/store20-stock-taking-service";
import { actions } from "redux-router5";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import routes from "../../routing/routes";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  reviewStockTakeFailed,
  reviewStockTakeLoaded,
  reviewStockTakeValidationError,
  ValidationErrorRecord,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export function* loadStockTakeReview(serviceContainer: ServiceContainer): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  try {
    const stockId = yield select(selectWarehouseId);
    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    const validatedItems: ValidatedItem[] = yield call(
      [stockTakingService, "reviewStockTake"],
      stockId,
      stockTakeId
    );

    const validationErrors: ValidationErrorRecord = Object.fromEntries(
      validatedItems
        .filter(
          (x) =>
            x.validationErrorType ||
            x.expectedQuantityWhenCounted !== x.expectedQuantity
        )
        .map(
          (x) =>
            [
              x.itemId,
              x.validationErrorType ?? "ExpectedQuantityDiffers",
            ] as const
        )
    );

    if (Object.keys(validationErrors).length !== 0) {
      yield put(reviewStockTakeValidationError(validationErrors));
    } else {
      yield put(
        actions.navigateTo(routes.stockTaking.manual.route, { modal: "review" })
      );
      yield put(reviewStockTakeLoaded(validatedItems));
    }
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:loadStockTakeReview:${errorCode}`,
      e,
      put(reviewStockTakeFailed(toErrorActionPayload(e)))
    );
  }
}
