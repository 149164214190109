import {
  ErrorKeysByCategory,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { NullableItem } from "@ahlsell-group/store20-stock-taking-service";
import { ModalAction, ModalActions } from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import ErrorModal from "../../error/components/ErrorModal";

interface ManualStockTakingItemErrorModalProps {
  bestEffortItemId: string;
  item?: NullableItem;
  enableRemove: boolean;
  overrideError?: GenericOperationError | "barcode-not-found";
  onClose: () => void;
  onRemove: () => void;
}

const getErrorKey = (
  item?: NullableItem,
  overrideError?: GenericOperationError | "barcode-not-found"
): ErrorKeysByCategory<"stockTakingItem"> => {
  if (overrideError) {
    return overrideError;
  }

  const type = item?.validationErrorType;

  // TODO The texts should be renamed instead of this mapping.
  switch (type) {
    case "Unknown":
      return "unknown";
    case "InvalidItemGlobally":
      return "invalid-item-globally";
    case "ItemExpiredGlobally":
      return "item-expired-globally";
    case "CannotAuditLot":
      return "cannot-audit-lot";
    case "InvalidItemLegalEntity":
      return "invalid-item-legal-entity";
    case "ItemExpiredLegalEntity":
      return "item-expired-legal-entity";
    case "ItemExpiredStock":
      return "item-expired-warehouse";
    case "InvalidItemStock":
      return "invalid-item-warehouse";
    case "MissingLocation":
      return "missing-location";
    case "ItemHasMovement":
      return "item-has-movement";
    case "PickingInProgress":
      return "picking-in-progress";
    case "ReturnInProgress":
      return "return-in-progress";
    case "RemedyItemInHj0216":
      return "remedy-item-in-hj0216";
    case "StockTakingInProgress":
      return "stock-taking-in-progress";
    case "DeliveryInProgress":
      return "delivery-in-progress";

    case "PreCheckFailed": // This "shouldn't" be possible, so consolidate it with "unknown".
    default:
      return "unknown";
  }
};

const ManualStockTakingItemErrorModal: React.FC<ManualStockTakingItemErrorModalProps> =
  function ({
    item,
    bestEffortItemId,
    enableRemove,
    overrideError,
    onClose,
    onRemove,
  }) {
    const { t } = useTranslation("common");

    const modalActions = (
      <ModalActions>
        <ModalAction
          mode="button"
          onClick={onClose}
          data-cy="ManualStockTakingItemErrorModal-ok"
        >
          {t("ok")}
        </ModalAction>
        {enableRemove && (
          <ModalAction
            mode="button"
            onClick={onRemove}
            color="error"
            data-cy="ManualStockTakingItemErrorModal-remove"
          >
            {t("remove")}
          </ModalAction>
        )}
      </ModalActions>
    );

    const extraContent = (
      <>
        {t("stockTaking.item")}{" "}
        <strong data-cy={`ManualStockTakingItemErrorModal-${bestEffortItemId}`}>
          {bestEffortItemId}
        </strong>
      </>
    );

    return (
      <ErrorModal
        category="stockTakingItem"
        error={{ reason: getErrorKey(item, overrideError) }}
        onClose={onClose}
        data-cy="ManualStockTakingItemErrorModal"
        data-error-reason={overrideError ?? item?.validationErrorType}
        modalActions={modalActions}
        extraContent={extraContent}
      />
    );
  };

export default ManualStockTakingItemErrorModal;
