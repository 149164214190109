import React from "react";

import View from "../../routing/components/View";
import routes from "../../routing/routes";

import ManualStockTakingClearModal from "./ManualStockTakingClearModal";
import ManualStockTakingItem from "./ManualStockTakingItem";
import ManualStockTakingItemRemoveModal from "./ManualStockTakingItemRemoveModal";
import ManualStockTakingList from "./ManualStockTakingList";
import ManualStockTakingScan from "./ManualStockTakingScan";
import ManualStockTakingSubmitCompleteModal from "./ManualStockTakingSubmitCompleteModal";
import ManualStockTakingSubmitErrorModal from "./ManualStockTakingSubmitErrorModal";
import ManualStockTakingSubmitModal from "./ManualStockTakingSubmitModal";

const ManualStockTakingRoot: React.FC = function () {
  // TODO S20-2276: Remember scroll position in ManualStockTakingList.
  return (
    <>
      <View
        route={[
          routes.stockTaking.manual,
          routes.stockTaking.manual.submit, // Modal
          routes.stockTaking.manual.submit.complete, // Modal
          routes.stockTaking.manual.remove, // Modal
        ]}
        exact
        component={ManualStockTakingList}
      />
      <ManualStockTakingScan />

      <View
        route={[
          routes.stockTaking.manual.item,
          routes.stockTaking.manual.item.remove, // Modal
        ]}
        component={ManualStockTakingItem}
      />
      <View
        route={routes.stockTaking.manual.remove}
        component={ManualStockTakingItemRemoveModal}
      />
      <View
        route={routes.stockTaking.manual.item.remove}
        component={ManualStockTakingItemRemoveModal}
      />
      <View
        route={routes.stockTaking.manual.submit}
        component={ManualStockTakingSubmitModal}
        exact
      />
      <View
        route={routes.stockTaking.manual.submit.complete}
        component={ManualStockTakingSubmitCompleteModal}
      />
      <View
        route={routes.stockTaking.manual.submit.error}
        component={ManualStockTakingSubmitErrorModal}
      />
      <View
        route={routes.stockTaking.manual}
        component={ManualStockTakingClearModal}
        exact
      />
    </>
  );
};

export default ManualStockTakingRoot;
