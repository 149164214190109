import { useTranslation } from "@ahlsell-group/app-localization";
import { ValidatedItem } from "@ahlsell-group/store20-stock-taking-service";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import router from "../../routing/router";
import routes from "../../routing/routes";
import useAutofocus from "../../routing/useAutofocus";
import useNavigate from "../../routing/useNavigate";
import useRoute from "../../routing/useRoute";
import { selectItems } from "../manualStockTakingSelectors";
import { updateItem } from "../manualStockTakingSlice";
import { SaveAction } from "../types";

import ManualStockTakingItemDiffModal from "./ManualStockTakingItemDiffModal";
import ManualStockTakingItemForm from "./ManualStockTakingItemForm";
import ManualStockTakingLoadingModal from "./ManualStockTakingLoadingModal";

interface ManualStockTakingValidItemProps {
  item: ValidatedItem;
}

const ManualStockTakingValidItem: React.FC<ManualStockTakingValidItemProps> =
  function ({ item }) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector(selectItems);
    const itemInList = item.canStockTake
      ? items.find((x) => x.itemId === item.itemId)
      : undefined;
    const [updatedQuantity, setUpdatedQuantity] = useState(
      itemInList?.stockTakingQuantity
    );
    const [updatedComment, setUpdatedComment] = useState(itemInList?.comment);
    const [diffModalSaveAction, setDiffModalSaveAction] =
      useState<SaveAction>("done");

    const [isFormOnTop] = useRoute(routes.stockTaking.manual.item, {
      exact: true,
    });

    const handleClose = () => navigate.back();
    const { acceptQuantity, modal } = router.getState().params;
    const { autofocus, handleAutofocus } = useAutofocus();
    const [isDiffModalOpen, navigateToDiffModal] = useRoute(
      routes.stockTaking.manual.item.diff
    );

    const save = async (
      saveAction: SaveAction,
      quantity: number,
      comment?: string,
      isInDiffModal: boolean = false
    ) => {
      if (isInDiffModal) {
        await navigate.back();
      }
      navigate(routes.stockTaking.manual.item, {
        itemId: item.itemId,
        modal: "saving",
      });
      dispatch(
        updateItem({
          item: { ...item, stockTakingQuantity: quantity, comment },
          continueScanning: saveAction === "continue",
        })
      );
    };

    const handleSave =
      (saveAction: SaveAction) => (quantity: number, comment?: string) => {
        const diffAccepted = quantity === acceptQuantity && !!comment;
        const shouldShowDiffModal =
          item.expectedQuantity !== quantity && !diffAccepted;

        if (shouldShowDiffModal) {
          setUpdatedQuantity(quantity);
          setUpdatedComment(comment);
          setDiffModalSaveAction(saveAction);
          navigateToDiffModal({ itemId: item.itemId });
          return;
        }

        save(saveAction, quantity, comment, false);
      };

    const handleRemove = () => {
      const { itemId } = item;
      navigate(routes.stockTaking.manual.item.remove, {
        itemId,
      });
    };

    const hasAcceptQuantity = typeof acceptQuantity === "number";
    const showComment =
      hasAcceptQuantity ||
      (!!itemInList &&
        item.canStockTake &&
        itemInList.stockTakingQuantity !== item.expectedQuantityWhenCounted);

    return (
      <>
        <ManualStockTakingItemForm
          item={item}
          onCancel={handleClose}
          onSaveAndContinue={handleSave("continue")}
          onRemove={handleRemove}
          isNew={itemInList === undefined}
          showDiff={hasAcceptQuantity}
          stockTakingQuantity={item.stockTakingQuantity ?? updatedQuantity}
          showComment={showComment}
          comment={itemInList?.comment}
          lastStockTakeDate={item.previousStockTakes?.[0]?.date}
          autofocusQuantity={isFormOnTop && autofocus}
          onAutofocusQuantity={handleAutofocus}
        />
        <ManualStockTakingItemDiffModal
          open={isDiffModalOpen}
          stockQuantity={item.expectedQuantityWhenCounted}
          stockTakingQuantity={updatedQuantity ?? -1}
          unit={item.unit}
          saveAction={diffModalSaveAction}
          onCancel={() => navigate.back()}
          onCorrect={async () => {
            await navigate.back();
            navigate(
              routes.stockTaking.manual.item,
              {
                itemId: item.itemId,
                autofocus: null,
                acceptQuantity: updatedQuantity ?? -1,
              },
              { replace: true }
            );
          }}
          onContinue={() =>
            save(
              diffModalSaveAction,
              updatedQuantity ?? -1,
              updatedComment,
              true
            )
          }
        />
        {modal === "saving" && (
          <ManualStockTakingLoadingModal
            headerText={t("stockTaking.updatingStockTake")}
            detailText={t("stockTaking.updatingItemInfoStockTake")}
            onClose={() => navigate.back()}
          />
        )}
      </>
    );
  };

export default ManualStockTakingValidItem;
