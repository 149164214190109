import { IStockTakeSubmission } from "@ahlsell-group/store20-stock-taking-service";
import { actions } from "redux-router5";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import routes from "../../routing/routes";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { selectIsReviewEnabled } from "../manualStockTakingSelectors";
import {
  submitStockTakesFailed,
  submitStockTakesRequested,
  submitStockTakesSucceeded,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export default function* submitStockTakes(
  serviceContainer: ServiceContainer,
  action: ActionType<typeof submitStockTakesRequested>
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  try {
    const isReviewEnabled: boolean | undefined = yield select(
      selectIsReviewEnabled
    );

    if (isReviewEnabled === undefined) {
      throw new Error("submitStockTakes: review flag not loaded");
    }

    const stockId: number = yield select(selectWarehouseId);
    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    const submission: IStockTakeSubmission = yield call(
      [stockTakingService, "submitStockTake"],
      stockId,
      stockTakeId,
      action.payload.enableAutomaticCompletion
    );

    yield put(submitStockTakesSucceeded());
    if (isReviewEnabled) {
      yield put(
        actions.navigateTo(
          routes.stockTaking.route,
          { highlightVivaldiId: submission.vivaldiId.toString() },
          { replace: true }
        )
      );
    } else {
      yield put(
        actions.navigateTo(
          routes.stockTaking.manual.submit.complete.route,
          { stockTakeId: submission.vivaldiId.toString() },
          { replace: true }
        )
      );
    }
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:submitStockTakes:${errorCode}`,
      e,
      put(submitStockTakesFailed(toErrorActionPayload(e)))
    );

    yield put(
      actions.navigateTo(
        routes.stockTaking.manual.submit.error.route,
        {},
        { replace: true }
      )
    );
  }
}
