import { GetItemResponse } from "@ahlsell-group/store20-stock-taking-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { TransitionSuccessAction } from "../../routing/types";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { itemLoaded, itemLoadFailed } from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export default function* loadItem(
  serviceContainer: ServiceContainer,
  action: TransitionSuccessAction
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  const { itemId } = action.payload.route.params;

  if (typeof itemId !== "string") throw new Error("Missing params.itemId");

  try {
    const stockId = yield select(selectWarehouseId);
    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    const getItemResponse: GetItemResponse = yield call(
      [stockTakingService, "getItem"],
      stockId,
      stockTakeId,
      itemId
    );

    yield put(itemLoaded(getItemResponse));
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:loadItem:${errorCode}`,
      e,
      put(itemLoadFailed(toErrorActionPayload(e, { itemId })))
    );
  }
}
