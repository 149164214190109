import { Warehouse } from "@ahlsell-group/store20-store-service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { userLoginSucceeded } from "../../app/appSlice";

import { WarehouseCoordinates } from "./types";

export interface WarehouseState {
  warehouseId: number;
  warehouseName?: string;
  warehouseCoordinates?: WarehouseCoordinates;
}

const initialState: WarehouseState = {
  warehouseId: 0,
};

const slice = createSlice({
  initialState,
  name: "warehouse",
  reducers: {
    reloadForWarehouseUpdateRequested() {},
    userWarehouseInfoUpdated(state, action: PayloadAction<Warehouse>) {
      state.warehouseName = action.payload.storeName;
      if (
        !Number.isNaN(parseFloat(action.payload.geoLat ?? "")) &&
        !Number.isNaN(parseFloat(action.payload.geoLong ?? ""))
      ) {
        state.warehouseCoordinates = {
          latitude: parseFloat(action.payload.geoLat!),
          longitude: parseFloat(action.payload.geoLong!),
        };
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(userLoginSucceeded, (state, action) => {
      state.warehouseId = action.payload.warehouseId;
    });
  },
});

export const {
  reducer: warehouseReducer,
  actions: { reloadForWarehouseUpdateRequested, userWarehouseInfoUpdated },
} = slice;
