import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch } from "react-redux";

import { triggerLoginRedirect } from "../../../app/appSlice";

interface AuthInteractionRequiredError {
  reason: "auth-interaction-required";
  data: { scopes: string[] };
}

interface ErrorAuthInteractionRequiredModalProps {
  error: AuthInteractionRequiredError;
  "data-cy"?: string;
}

export const isAuthInteractionRequired = (error: {
  reason: string;
  data?: any;
}): error is AuthInteractionRequiredError =>
  error?.reason === "auth-interaction-required" &&
  Array.isArray(error.data?.scopes);

const ErrorAuthInteractionRequiredModal: React.FC<ErrorAuthInteractionRequiredModalProps> =
  function ({ error, ...rest }) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();

    return (
      <Modal open variant="card">
        <ModalHeader {...rest}>
          {t("errors.general.auth-interaction-required__header")}
        </ModalHeader>
        <ModalContent>
          {t("errors.general.auth-interaction-required__detail")}
        </ModalContent>
        <ModalActions>
          <ModalAction
            mode="button"
            onClick={() => dispatch(triggerLoginRedirect(error.data))}
            data-cy="ErrorModal-logIn"
          >
            {t("logIn")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default ErrorAuthInteractionRequiredModal;
