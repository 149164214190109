import {
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { ProgressDotsLinear } from "@ahlsell-group/app-ui/ProgressDotsLinear";
import { CircleExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import stockTakenSubmissionDateConfig from "../config/stockTakenSubmissionDateConfig";
import { selectStockTakenSubmissionState } from "../stockTakenSubmissionSelectors";
import { submissionsRequested } from "../stockTakenSubmissionSlice";
import { doesStockTakeHaveProblem } from "../util/doesStockTakeHaveProblem";

import StockTakeSubmissionListItem from "./StockTakeSubmissionListItem";

const scrollIntoViewCallback = (element: HTMLElement | null) =>
  element?.scrollIntoView({
    behavior: window.matchMedia(`(prefers-reduced-motion: reduce)`).matches
      ? "instant"
      : "smooth",
  });

interface StockTakenSubmissionListProps {
  highlightVivaldiId?: string;
}

const StockTakenSubmissionList: React.FC<StockTakenSubmissionListProps> =
  function ({ highlightVivaldiId }) {
    const { t } = useTranslation("common");
    const state = useSelector(selectStockTakenSubmissionState);
    const dispatch = useDispatch();
    const { showSubmittedStockTakesForDays } = stockTakenSubmissionDateConfig();
    const [scrollToStockTakeId, setScrollToStockTakeId] = useState<string>();

    const errorMessage = useErrorMessage(
      "stockTakenSubmission",
      state.type === "error" ? state.error : "unknown"
    );

    if (state.type === "idle") {
      throw new Error(
        "[internal error] StockTakenSubmissionList stuck in idle state"
      );
    }

    const stockTakes = state.type === "loaded" ? state.submissions : undefined;
    const problemStockTakes = stockTakes?.filter(doesStockTakeHaveProblem);

    return (
      <div className="py-4">
        <Typography className="px-2 pb-4" color="gray" variant="body-sm">
          {t("stockTaking.stockTakenSubmissions")}
          {state.type === "loading" && (
            <ProgressDotsLinear
              size="small"
              className="ml-4"
              delayDisplay
              data-cy="StockTakeSubmissions-loading"
            />
          )}
        </Typography>

        {state.type === "error" && (
          <Alert severity="error" data-cy="StockTakeSubmissions-loadError">
            <div className="flex flex-col gap-1 text-body-sm">
              <div>
                <Trans t={t} i18nKey={errorMessage?.headerKey} />
              </div>
              <div>
                <Trans t={t} i18nKey={errorMessage?.detailKey} />
              </div>
              <div>
                <LinkButton
                  className="-ml-2"
                  onClick={() => dispatch(submissionsRequested())}
                >
                  {t("tryAgain")}
                </LinkButton>
              </div>
            </div>
          </Alert>
        )}

        {problemStockTakes && problemStockTakes.length > 0 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setScrollToStockTakeId(problemStockTakes[0].stockTakeId);
              setTimeout(() => {
                setScrollToStockTakeId(undefined);
              }, 100);
            }}
            data-cy="StockTakeSubmissions-problem"
          >
            <Alert
              icon={CircleExclamationIcon}
              severity="error"
              className="mb-2"
            >
              <Typography variant="body-sm">
                {t("stockTaking.submittedStockTakeProblem", {
                  count: problemStockTakes.length,
                  vivaldiIds: problemStockTakes.map((x) => `#${x.vivaldiId}`),
                })}
              </Typography>
            </Alert>
          </a>
        )}

        {stockTakes?.length === 0 && (
          <Typography
            className="px-2"
            color="gray"
            variant="body-sm"
            data-cy="StockTakeSubmissions-empty"
          >
            {t("stockTaking.stockTakenSubmissionsEmpty", {
              count: showSubmittedStockTakesForDays,
            })}
          </Typography>
        )}

        {stockTakes !== undefined && stockTakes.length > 0 && (
          <div data-cy="StockTakenSubmissions-list">
            {stockTakes.map((x) => (
              <StockTakeSubmissionListItem
                key={x.vivaldiId}
                submission={x}
                highlight={highlightVivaldiId === x.vivaldiId?.toString()}
                ref={
                  x.stockTakeId === scrollToStockTakeId
                    ? scrollIntoViewCallback
                    : undefined
                }
              />
            ))}
          </div>
        )}
      </div>
    );
  };

export default StockTakenSubmissionList;
