/**
 * Formats the comment according to Vivaldi's shortcoming.
 * @description Removes line breaks and replaces them with a dot and a space
 */
export const formatComment = (comment: string | undefined) =>
  comment
    ?.trim()
    .replace(/^ +| +$/gm, "")
    .replace(/([^.\n])\n+/g, "$1. ")
    .replace(/\n+/g, " ");
