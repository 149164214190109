import { StockTake } from "@ahlsell-group/store20-stock-taking-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import stockTakenSubmissionDateConfig from "../config/stockTakenSubmissionDateConfig";
import {
  submissionsLoaded,
  submissionsLoading,
  submissionsLoadingError,
} from "../stockTakenSubmissionSlice";

export default function* loadStockTakenSubmissions({
  handleSagaError,
  stockTakingService,
}: ServiceContainer): Saga {
  try {
    const warehouseId: number = yield select(selectWarehouseId);

    const toDate = stockTakenSubmissionDateConfig();

    yield put(submissionsLoading());

    const stockTakes: StockTake[] = yield call(
      [stockTakingService, "getSubmittedStockTakes"],
      warehouseId,
      toDate.date
    );

    yield put(submissionsLoaded(stockTakes));
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) => `saga:stock-taking:loadStockTakenSubmissions:${errorCode}`,
      e,
      put(submissionsLoadingError(toErrorActionPayload(e)))
    );
  }
}
